import React from 'react'

const LeftArrow = props => (
  <svg width={23} height={11} {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M23 5.923H1v-1h22z" />
      <path d="M5.654 10.884L.269 5.5l.707-.707 5.385 5.384z" />
      <path d="M6.252.714L.868 6.098.16 5.391 5.545.006z" />
    </g>
  </svg>
)

export default LeftArrow
