import React from 'react'

const Chevron = props => (
  <svg width={23} height={13} {...props}>
    <path
      d="M21.858 0L11.5 10.65 1.144 0 0 1.176 11.5 13 23 1.175z"
      fill="#666D70"
      fillRule="evenodd"
    />
  </svg>
)

export default Chevron
