import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Intro from '../components/intro'
import ToggleContent from '../components/ToggleContent'
import Staff from '../components/Staff'
import SEOPage from '../components/SEO/Page'

import ToiToi from '../images/toitoi.png'

class AboutPage extends React.Component {
  render() {
    const { location, data } = this.props
    const aboutContent = data.prismicAbout.data

    var heroImgs = []
    var heroImgsMobile = []

    aboutContent.hero.forEach(item => {
      heroImgs.push(item.desktop_image.url)
      heroImgsMobile.push(item.mobile_image.url)
    })

    var backgroundStyle = {
      backgroundImage: `url(${aboutContent.location_image.url})`,
      backgroundSize: `cover`,
    }

    return (
      <Layout location={location}>
        <SEOPage
          title={aboutContent.title && aboutContent.title}
          location={location}
        />
        <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />
        <Intro
          id="about"
          title={aboutContent.lead_title}
          lead={aboutContent.lead_text.html}
        />
        <div id="about-location" className="lg:flex mb-24 lg:mb-0 lg:bg-grey">
          {aboutContent.location_image.url && (
            <div className="lg:w-1/2">
              <div className="ratio" style={backgroundStyle} />
            </div>
          )}
          <div className="lg:w-1/2 bg-grey text-white">
            <div className="w-full h-full pt-16 lg:pt-10 pb-16 pl-3 pr-5 lg:pl-16 lg:pr-14 lg:flex flex-col justify-between">
              {aboutContent.location_address && (
                <div>
                  <p className="text-sm mb-16">
                    {aboutContent.location_address}
                  </p>
                </div>
              )}
              {aboutContent.location_text.html && (
                <div className="lg:flex justify-end">
                  <div className="w-1/10" />
                  <div
                    className="rte lg:w-9/10 tracking-2xl text-2xl leading-2xl lg:text-lg xl:text-2xl leading-2xl"
                    dangerouslySetInnerHTML={{
                      __html: aboutContent.location_text.html,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="our-values" className="px-3 lg:px-14 lg:pt-25">
          <div className="lg:flex -mx-2 mb-6">
            <div className="lg:w-1/2 px-2 mb-12">
              <div className="lg:w-9/10 xl:w-4/5">
                {aboutContent.values_title && (
                  <h3 className="tracking-2xl mb-12 text-4xl font-light leading-tight text-grey w-48 lg:w-full">
                    {aboutContent.values_title}
                  </h3>
                )}
                {aboutContent.values_text.html && (
                  <div
                    className="rte pr-2 lg:pr-0 mb-12"
                    dangerouslySetInnerHTML={{
                      __html: aboutContent.values_text.html,
                    }}
                  />
                )}
                <div className="hidden lg:block w-3/5">
                  <img src={ToiToi} alt="Toi Toi with birds" />
                </div>
              </div>
            </div>
            {aboutContent.values.length && (
              <div className="lg:w-1/2 px-2">
                <div className="sm:flex flex-wrap text-2xl leading-2xl tracking-2xl lg:text-lg text-grey xl:text-2xl leading-2xl tracking-2xl leading-lg">
                  {aboutContent.values.map((item, i) => {
                    return (
                      <div key={i} className="sm:w-1/2 mb-12 lg:mb-20 px-2">
                        <div className="w-20 mb-2">
                          <img
                            src={item.value_image.url}
                            alt={`Icon for ${item.value_title}`}
                          />
                        </div>
                        {item.value_title && <h4>{item.value_title}</h4>}
                        {item.value_subtitle && <p>{item.value_subtitle}</p>}
                        {item.value_description && (
                          <p className="text-base text-charcoal mt-2 pr-5">
                            {item.value_description}
                          </p>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {aboutContent.values_image.url && (
              <div className="w-5/6 lg:hidden">
                <img
                  src={aboutContent.values_image.url}
                  alt={aboutContent.values_image.alt}
                />
              </div>
            )}
          </div>
        </div>
        <Staff
          id="executive-members"
          title={aboutContent.senior_title}
          data={aboutContent.senior_members.map(senior_members => ({
            image: senior_members.senior_image.url,
            name: senior_members.senior_member_name,
            description: senior_members.senior_member_text.html,
            disable_image_preview: senior_members.disable_image_preview,
          }))}
        />
        <div className="mt-12 lg:pt-25">
          <Staff
            id="board-members"
            title={aboutContent.board_title}
            data={aboutContent.members.map(member => ({
              image: member.member_image.url,
              name: member.member_name,
              description: member.member_text.html,
            }))}
          />
        </div>

        <Intro
          id="our-carbon-zero-story"
          title={aboutContent.sustainability_title}
          lead={aboutContent.sustainability_text.html}
          imageUrl={aboutContent.sustainability_image.url}
          imageAlt={
            aboutContent.sustainability_image.alt || 'Sustainability Image'
          }
          imagePosition="secondary"
          embed={aboutContent.sustainability_embed.html}
        />

        <div id="community-involvement" className="px-3 lg:px-14">
          {aboutContent.community_title && (
            <h3 className="tracking-2xl mb-12 text-4xl font-light leading-tight text-grey">
              {aboutContent.community_title}
            </h3>
          )}
          <div className="lg:flex -mx-2 mb-16 lg:mb-48">
            {aboutContent.dropdowns.map((item, i) => {
              return (
                <div key={i} className="lg:w-1/2 px-2 mb-24 lg:mb-0">
                  <ToggleContent
                    title={item.dropdown_title}
                    text={item.dropdown_text.html}
                    logo={item.dropdown_logo.url}
                    alt={item.dropdown_logo.alt}
                    link={item.dropdown_link.url}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageAboutQuery = graphql`
  query {
    prismicAbout {
      data {
        title
        hero {
          desktop_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
          mobile_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
        }
        lead_title
        lead_text {
          html
        }
        location_image {
          url
          localFile {
            childImageSharp {
              id
            }
          }
        }
        location_address
        location_text {
          html
        }
        values_title
        values_text {
          html
        }
        values_image {
          url
          alt
          localFile {
            childImageSharp {
              id
            }
          }
        }
        values {
          value_image {
            url
            localFile {
              childImageSharp {
                id
              }
            }
          }
          value_title
          value_subtitle
          value_description
        }
        board_title
        members {
          member_name
          member_text {
            html
          }
          member_image {
            url
            localFile {
              childImageSharp {
                id
              }
            }
          }
        }
        senior_title
        senior_members {
          disable_image_preview
          senior_member_name
          senior_member_text {
            html
          }
          senior_image {
            url
            localFile {
              childImageSharp {
                id
              }
            }
          }
        }
        community_title
        dropdowns {
          dropdown_title
          dropdown_text {
            html
          }
          dropdown_logo {
            url
            alt
          }
          dropdown_link {
            url
          }
        }
        sustainability_title
        sustainability_text {
          html
        }
        sustainability_image {
          url
          alt
        }
        sustainability_embed {
          html
        }
      }
    }
  }
`

export default AboutPage
