import React from 'react'
import { Element } from 'react-scroll'
import RightArrow from '../SVG/RightArrow'
import LeftArrow from '../SVG/LeftArrow'
import arrow from '../../images/up-chevron.svg'

class StaffItem extends React.Component {
  state = {
    active: null,
    activeMobile: false,
    windowWidth: null,
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount = () => {
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowWidth)
  }

  clickHandler = () => {
    this.setState({ activeMobile: !this.state.activeMobile })
  }

  render() {
    const { windowWidth, activeMobile } = this.state
    const {
      image,
      name,
      description,
      disable_image_preview,
      clickHandler,
    } = this.props

    const onClick = disable_image_preview
      ? ''
      : windowWidth < 990
      ? this.clickHandler
      : clickHandler
    return (
      <button
        onClick={onClick}
        className={`block text-left text-white lg:w-1/4 focus:outline-none ${
          activeMobile
            ? 'pointer-events-none lg:pointer-events-auto lg:cursor-pointer'
            : disable_image_preview
            ? 'pointer-events-none'
            : 'cursor-pointer'
        }`}
      >
        <div className="ratio w-full relative">
          {image && <img src={image} alt={name} />}
          {name && (
            <div className="absolute pin-b pin-l px-3 my-5 lg:m-5">
              <p className="tracking-lg leading-lg">{name}</p>
            </div>
          )}
        </div>
        <div
          className={`rte bg-grey px-3 lg:hidden pt-16 pb-8 text-lg ${
            activeMobile ? 'block' : 'hidden'
          }`}
        >
          {' '}
          {description && (
            <div
              className="mb-12"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          <div className="text-center">
            <img
              className="cursor-pointer pointer-events-auto inline-block p-4 -m-4"
              src={arrow}
              alt="down arrow"
            />
          </div>
        </div>
      </button>
    )
  }
}

class Staff extends React.Component {
  state = {
    active: null,
  }

  clickHandler = i => {
    this.setState({
      active: i,
    })
  }

  backHandler = () => {
    this.setState({
      active: null,
    })
  }

  render() {
    const { data, id, title } = this.props

    return (
      <div id={id} className="relative">
        <Element
          name="staff"
          className="lg:flex bg-grey-light flex-wrap text-white text-2xl leading-2xl tracking-2xl"
        >
          <div className="lg:w-1/4 bg-grey p-5">
            <div className="ratio w-full relative">
              <p>{title}</p>
              <div className="svg-white absolute pin-b pin-l lg:relative">
                <RightArrow />
              </div>
            </div>
          </div>
          {data.map((staff, i) => (
            <StaffItem
              key={i}
              name={staff.name}
              image={staff.image}
              description={staff.description}
              disable_image_preview={staff.disable_image_preview}
              clickHandler={() => this.clickHandler(i)}
            />
          ))}
        </Element>
        <Element
          name="featuredStaff"
          className={`w-full absolute pin-t transition ${
            this.state.active !== null ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {this.state.active !== null && (
            <div className="lg:flex mb-35 hidden lg:block">
              <div className="lg:w-1/2 bg-grey text-white">
                <div className="ratio">
                  <div className="ratio__content overflow-y-scroll">
                    <div className="pt-10 w-full pb-20 lg:pb-10 pl-16 pr-14 lg:flex flex-col justify-between">
                      <button
                        onClick={this.backHandler}
                        className="block text-left text-white focus:outline-none"
                      >
                        <p className="text-2xl leading-2xl mb-2">Back</p>
                        <div>
                          <LeftArrow />
                        </div>
                      </button>
                      <div>
                        <div className="w-1/10" />
                        <div className="w-9/10 text-lg tracking-lg leading-lg mt-16 xl:mt-64">
                          <h4 className="text-2xl leading-2xl font-normal mb-6">
                            {data[this.state.active].name}
                          </h4>
                          <div
                            className="rte"
                            dangerouslySetInnerHTML={{
                              __html: data[this.state.active].description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2">
                <div
                  className="ratio bg-cover"
                  style={{
                    backgroundImage: `url(${data[this.state.active].image})`,
                  }}
                />
              </div>
            </div>
          )}
        </Element>
      </div>
    )
  }
}

export default Staff
