import React from 'react'
import Chevron from '../SVG/Chevron'

class ToggleContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggleContent = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { title, text, logo, link, alt } = this.props

    return (
      <div className="lg:w-9/10">
        <button
          className="flex items-center mb-8 text-left w-full focus:outline-none hover:opacity-75 focus:opacity-75 transition"
          onClick={this.toggleContent}
        >
          {title && (
            <h4 className="w-2/3 lg:w-auto text-2xl tracking-2xl leading-2xl font-light text-grey mr-5">
              {title}
            </h4>
          )}
          <div className="w-1/3 lg:w-auto text-right">
            <Chevron className={this.state.isOpen ? 'rotate-180' : ''} />
          </div>
        </button>
        <div className={this.state.isOpen ? 'block' : 'hidden'}>
          {text && (
            <div
              className="rte mb-4 tracking-lg leading-lg"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer mb-24 inline-block font-extrabold underline text-charcoal"
          >
            Visit their website
          </a>
          {logo && (
            <div>
              <img src={logo} alt={alt} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ToggleContent
